/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';

import { ImageContainer, PhotoContainerV2 } from './styles';
import Waiting from '../Waiting';
// import DeletePostPopUp from '../DeletePostPopup';

import { ImageUrl } from '../../utils/constants';

import { getCommentsOnPost, addCommentReplyOnPost, likeUnlikePost, disableButton, getPostLikeDetails, addCommentOnPost } from '../../redux/actions';
import ViewPhotoCommentModal from '../ViewCommentModal/viewPhotoCommentModal';
import { HeaderContainer, UserInformation, ImageWrapper, Description } from '../SocialFeeds/styles';
import { convertDateInTimezone, convertUnicode, applyHighlights, createSafeHTML } from '../../utils/methods';
import moment from 'moment';
import { TimeOfPost, LikeButtonew, FeedTabNew, RecognitionText, FeedInformation, FieldContainer, CommentContent, InputFieldContainer, Option, RecognitionSection } from '../SocialFeedsV2/styles';
import { StyledModal, StyledBody, MainContent, PeerButton } from '../RecognitionList/styles';
import SocialFeedTextarea from '../SocialFeedsV2/SocialFeedTextarea';
import Image from '../Image';
import { isNull } from 'lodash';
import { withTranslation } from 'react-i18next';


class SinglePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      showModalFor: -1,
      showDeleteModal: false,
      hideCommentText: props.hideCommentText === false ? false : true,
      showRecognitionModal: false,
      userIdArray: [],
      userIdArrayV2: [],
      showGivenShoutout: false,
      users: [],
      usersV2: [],
      commentText: '',
      shoutoutText: null,
      shoutoutImage: null,
      taggedUser: '',
      showTextArea: false,
      notBase64: false,
      time: [],
      mentionedUsers: [],
      exemplifyID: null,
      isPostLiked: props.photo && props.photo.own_like_post === 1,
      photoLikeStatus: null,
      postId: ''
    }
  }

  componentDidMount() {
    const { photo } = this.props;
    if (photo && photo.own_like_post === 1) {
      this.setState({
        photoLikeStatus: true
      })
    } else {
      this.setState({
        photoLikeStatus: false
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.successLikeOrUnlike !== nextProps.successLikeOrUnlike) {
      this.setState({
        clicked: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postLikeDetails !== prevProps.postLikeDetails) {
      let photos = this.props.userPosts;
      let photoIndex = photos.findIndex((data) => data.id == this.state.postId);
      if (photoIndex !== -1) {
        photos[photoIndex].post_like_users = this.props.postLikeDetails;
        this.props.updateFeedsLike(photos);
        this.setState({ postLikeDetail: this.props.postLikeDetails, postId: '' });
      }
    }
  }


  likeUnlikePost = () => {
    const { likeOrUnlikePost, disableLikeButton, photo, updationList } = this.props;
    const likeData = {
      post_id: photo.id,
      flag: photo.own_like_post === 1 ? 'unlike' : 'like',
    };
    this.setState((prev) => ({
      clicked: true,
      isPostLiked: !prev.isPostLiked,
      postId: '',
      photoLikeStatus: !prev.photoLikeStatus,
      postLikeDetail: []
    }), () => this.setState({ postId: photo.id }));
    disableLikeButton();
    likeOrUnlikePost(likeData, updationList || 'userPostImages');
  };

  onHide = () => this.setState({ showModalFor: -1 });

  hideDeleteModal = () => this.setState({ showDeleteModal: false });

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  updateShoutoutArray = (mentionedUsers, text, image, exemplifies_id) => {
    this.setState({
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplifies_id ? exemplifies_id : null,
      mentionedUsers: mentionedUsers,
      exemplifyID: exemplifies_id ? exemplifies_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      usersV2: '',
      userIdArrayV2: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  showRecognition = () => {
    this.setState((prev) => ({
      showRecognitionModal: !prev.showRecognitionModal
    }));
  }

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.state;
    this.setState((prev) => ({
      showRecognitionModal: !prev.showRecognitionModal,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: id ? id : null,
      exemplifyID: id ? id : null
    }));
    this.updateShoutoutArray(userIdArray, text, image, id);
  }

  renderRecognitionModal = () => {
    const { exemplifiesValues } = this.props;
    return (
      <div>
        <StyledModal
          show={this.state.showRecognitionModal}
          onHide={this.showRecognition}
        >
          <StyledBody>
            <MainContent>
              {
                exemplifiesValues ? exemplifiesValues.map((recognition) => (
                  <PeerButton
                    value={recognition.core_value}
                    onClick={() => this.onShoutoutSelect(recognition.core_value, recognition.id, recognition.image)}
                    key={recognition.id}
                  >
                    <img src={`${ImageUrl}/${recognition.image}`} height={recognition.image.includes('passionate.png') ? '30px' : '20px'} />
                    <div>{recognition.core_value}</div>
                  </PeerButton>
                ))
                  : null
              }
            </MainContent>
          </StyledBody>
        </StyledModal>
      </div>
    )
  }

  addInspirationV2 = (e) => {
    let newText = this.state.commentText.slice(0, this.state.position.start) + e.native + this.state.commentText.slice(this.state.position.end);
    this.setState({
      position: { start: this.state.position.start + e.native.length, end: this.state.position.end + e.native.length },
      commentText: newText,
    });
  };

  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if (userIdArray.length !== 0) {
      if (userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if (value && value.trim() !== '') {
      return false;
    }
    return true;
  };


  updateTextV2 = (text, userId, taggedUserName, bool) => {
    const { userIdArrayV2, usersV2, taggedUser, userIdArray } = this.state;
    let userIdArr = [...userIdArrayV2];
    let userNameArrV2 = [...usersV2];
    let userIdAll = [...userIdArray]
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userIdAll.push(userId);
      userNameArrV2.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      userIdArrayV2: userIdArr,
      usersV2: userNameArrV2,
      taggedUser: taggedUsers,
      showGivenShoutout: bool,
      userIdArray: userIdAll
    });
  };


  updateText = (text, userId, taggedUserName) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      commentText: text,
      userIdArray: userIdArr,
      users: userNameArr,
    });
  };


  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }

  navigateUserProfile = (uid) => {
    const { history, userId, fetchUserPosts } = this.props;
    fetchUserPosts(uid, history);
    if (userId === uid) {
      history.push('/profile');
    } else {
      history.push(`/profile/${uid}`);
    }
  };


  imgSrc = (isOwn) => {
    const { userId, profileImage, photo } = this.props;
    const { notBase64 } = this.state;
    if (isOwn) {
      if (!notBase64) {
        return (<img src={`${ImageUrl}/${profileImage}`} />)
      }
      else {
        return (<Image image={profileImage} />)
      }
    } else if (userId == photo.uid) {
      if (!notBase64) {
        return (<img src={`${ImageUrl}/${profileImage}`} onClick={() => this.navigateUserProfile(photo.uid)} />)
      }
      else {
        return (<Image image={profileImage} onClick={() => this.navigateUserProfile(photo.uid)} />)
      }
    }
    else {
      return (<Image image={photo.profile_image} onClick={() => this.navigateUserProfile(photo.uid)} />)
    }
  };

  onChangeValue = (e) => {
    let position = this.getPosition(e.target)
    let text =  e.target.value
    this.setState({
      commentText: text,
      position: position
    });
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";</span>");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  showTextArea = () => {
    this.setState((prev) => ({
      showTextArea: !prev.showTextArea
    }));
  };

  addComment = () => {
    const { users, userIdArray, commentText, taggedUser, mentionedUsers, exemplifyID } = this.state;
    const { postComment, updationList, getCommentsForPost, photo } = this.props;
    let shoutout = {
      exemplifies: mentionedUsers.length > 0 ? mentionedUsers : userIdArray
    };
    let comment = this.toCodePoints(commentText), shoutout_user_name = '';
    if (!_.isEmpty(mentionedUsers) || !_.isEmpty(userIdArray)) {
      shoutout_user_name = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    let exemplify_id = exemplifyID;
    if (shoutout.exemplifies.length >= 1 || commentText !== "") {
      postComment({ post_id: photo.id, comment, shoutout, shoutout_user_name, exemplify_id }, updationList);
    }

    this.setState({
      showTextAreaFor: null,
      commentText: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      taggedUser: '',
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      displayEmojiPicker: false,
      shoutoutType: 0,
      userIdArrayV2: [],
      usersV2: []
    }, () => getCommentsForPost(photo.id))
  };

  

  renderTextArea = (value) => {
    const { handlePhotoPostModal } = this.props;
    return (
      <FieldContainer isCommentOrReply socialPage borderTop={"1"} width>
        <CommentContent height={this.state.showGivenShoutout ? 1 : 0}>
          <ImageWrapper isCommentSection>
            {this.imgSrc(true)}
          </ImageWrapper>
          <InputFieldContainer comment padding={'15px'} height={'100%'} width isCommentOrReply>
            <SocialFeedTextarea
              userCompany={this.props.userCompany}
              addInspiration={this.onChangeValue}
              addInspirationV2={this.addInspirationV2}
              updateTextV2={this.updateTextV2}
              inspirationQuote={this.state.commentText}
              updateText={this.updateText}
              users={this.state.users}
              usersV2={this.state.usersV2}

              userIdArray={this.state.userIdArray}
              placeholder={this.props.t("Write your comment here")}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={this.props.enableTrigger}
              myref={this.myref}
              className={'CustomTextAreaField'}

              shoutOutData={null}

            />

            <Option>
              <div onClick={() => handlePhotoPostModal(1, this.props.feed.id)}>
                <img src="/public/images/camera_icon.svg" />
              </div>
              {/* <div onClick={() => this.showEmojiPicker()}>
                <img src='/public/images/emoji_icon.svg' />
              </div> */}
              <div onClick={this.addComment} disabled={this.togglePostButton(value)}>
                <img style={{ marginTop: '5px' }} src="/public/images/social/send.svg" />
              </div>
            </Option>
          </InputFieldContainer>
        </CommentContent>

        <RecognitionSection>
          <div>
            <div onClick={() => this.displayEmojiContainer()}>
              <img src="/public/images/social/peer_recognition.svg" />
            </div>
            <div>
              <img src="/public/images/CommentReplyImages/camera.png" />
              <input
                id="uploadPhoto"
                type="file"
                name="image"
                accept=".jpeg, .png, .jpg*"
                multiple={false}
                onChange={(e) => this.props.onChange(e)}
                onClick={(e) => e.target.files[0] && this.props.onChange(e)}
              />
            </div>
            <div onClick={() => this.showEmojiPicker()}>
              <img src="/public/images/CommentReplyImages/Emoji_2.png" />
            </div>
          </div>
          <button onClick={this.addComment} disabled={this.togglePostButton(value)}>
            <img src="/public/images/CommentReplyImages/addPost.png" />
          </button>
        </RecognitionSection>
      </FieldContainer>
    )
  }

  handleLikePopUp = () => {
    const { handleModal, handleFeed, photo } = this.props
    handleModal();
    handleFeed(photo.id);
  }



  render() {
    const { photo, comments, replySuccess, disableButton, currentUserId, renderViewCommentModal, /*renderViewCommentAndGetComment,*/ imageV, showViewCommentModals, user, getCommentsForPost } = this.props;
    const { clicked, commentText, photoLikeStatus } = this.state;
    if ( isUndefined(photo) ||isNull(photo) ) {
      return <Waiting />
    }
    return (
      <React.Fragment>
        {(showViewCommentModals || showViewCommentModals == undefined) && <HeaderContainer>
          <ImageWrapper display={1} borderRadius={'3px'}>
            <img onError={this.fallBack} src={`${ImageUrl}/${user && user.profile_image}`} />
          </ImageWrapper>
          <UserInformation  >
            <span>{(photo.fname && photo.lname) ? (photo.fname + ' ' + photo.lname) : userName}</span>
            <div>
              {photo.location}
            </div>
          </UserInformation>
        </HeaderContainer>}
        <FeedInformation>
          {
            photo.core_value === null ? null : (showViewCommentModals || showViewCommentModals == undefined) && (
              <RecognitionText>
                <span className="first-span">
                  {photo.core_value === "Happy Birthday" || photo.core_value === "Work Anniversary" ? this.props.t("Celebrating") : this.props.t("Giving")}
                  <img src={`https://s3.amazonaws.com/images.trainingamigo.com/${photo.recognition_image}`} />
                  <span className='recognition-name'>{this.props.t(photo.core_value)}</span> {this.props.t("to")}
                </span>
                <span className="second-span">
                </span>
              </RecognitionText>
            )
          }
          {photo.description && (showViewCommentModals || showViewCommentModals == undefined) && <Description style={{ padding: '10px 25px 0px' }} width="true" contents /* dangerouslySetInnerHTML={{ __html: convertUnicode(photo.description).split('\\n').join('<br />') }} / */>{createSafeHTML(convertUnicode(photo.description).split('\\n').join('<br />'))}</Description>}
          {(showViewCommentModals || showViewCommentModals == undefined) && <TimeOfPost>
            {moment(convertDateInTimezone(photo.created_at)._d).format('MMM DD, YYYY')}
          </TimeOfPost>}
          {(showViewCommentModals || showViewCommentModals == undefined) && <FeedTabNew background={"none"}>
            <LikeButtonew onClick={this.likeUnlikePost} border={"1"}>
              <div>
                <div>
                  {photoLikeStatus ? <img src="/public/images/CommentReplyImages/like2.svg" /> : <img src="/public/images/CommentReplyImages/unlike.svg" />}
                </div>
                <div>{this.props.t('Like')}</div>
              </div>
            </LikeButtonew>
            <LikeButtonew onClick={this.showRecognition} border={"1"}>
              <div>
                <div>
                  <img src="/public/images/social/peer_recognition.svg" />
                </div>
                <div>{this.props.t('Recognition')}</div>
              </div>
            </LikeButtonew>
            <LikeButtonew onClick={() => getCommentsForPost(photo.id)}>
              <div>
                <div>
                  <img src="/public/images/social/comment_icon.svg" />
                </div>
                <div>{this.props.t('Comment')}</div>
              </div>
            </LikeButtonew>
          </FeedTabNew>}
          {(showViewCommentModals || showViewCommentModals == undefined) && this.renderTextArea(commentText)}
        </FeedInformation>
        {this.state.hideCommentText && <PhotoContainerV2 marginRight={(this.props.indexProp + 1) % 4 === 0 ? "0px" : "25px"}>
          {photo.image && this.state.hideCommentText ? <ImageContainer>
            <img
              src={`${ImageUrl}/${photo.image}`}
              alt={photo.image}
              onClick={() => {this.props.renderViewCommentAndGetComment(photo)}}
            />
          </ImageContainer> : null}
        </PhotoContainerV2>}
        {this.state.showTextArea && this.renderTextArea(commentText)}
        {this.state.showRecognitionModal && this.renderRecognitionModal()}
        {showViewCommentModals && this.props.imageV.id === photo.id ? <ViewPhotoCommentModal onClose={renderViewCommentModal} showModal={showViewCommentModals} photo={imageV}
          comments={comments}
          // postReply={postReplyOnComment}
          replySuccess={replySuccess}
          disableButton={disableButton}
          // likeUnlikePost={this.likeUnlikePost}
          clicked={clicked}
          currentUserId={currentUserId}
          history={history}
          description={imageV.description}
          exemplifiesValues={this.props.exemplifiesValues}
          handlePhotoPostModal={this.handlePhotoPostModal}
          getCommentsForPost={this.props.getCommentsForPost}
          profileImage={this.props.profileImage}
          enableTrigger={this.props.enableTrigger}
          handleModal={this.props.handleModal}
          handleFeed={this.props.handleFeed}
          feedNumber={this.props.feedNumber}
          updateFeedsLike={this.props.updateFeedsLike}
          userPosts={this.props.userPosts}
          updationList="userPostImages"
          onChange={this.props.onChange}
          userId={this.props.userId}
        >
        </ViewPhotoCommentModal> : null}
      </React.Fragment>
    )
  }
}

SinglePhoto.propTypes = {
  history: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
  fetchCommentsOnPost: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  comments: PropTypes.array,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  likeOrUnlikePost: PropTypes.func.isRequired,
  disableLikeButton: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  successLikeOrUnlike: PropTypes.string,
  currentUserId: PropTypes.number.isRequired,
  showViewCommentModal: PropTypes.bool,
  renderViewCommentAndGetComment: PropTypes.func,
  renderViewCommentModal: PropTypes.func,
  imageV: PropTypes.string,
  showViewCommentModals: PropTypes.bool,

  user: PropTypes.object,
  hideCommentText: PropTypes.bool,


  postComment: PropTypes.func.isRequired,
  getCommentsForPost: PropTypes.func.isRequired,
  getCommentsForPostModal: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  updationList: PropTypes.string,
  changeEditDeletePopup: PropTypes.func.isRequired,
  openEditAndDelete: PropTypes.func.isRequired,
  editAndDelete: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  fetchUserId: PropTypes.func,
  description: PropTypes.string,
  commentCount: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  taggedUsers: PropTypes.string,
  userCompany: PropTypes.object.isRequired,
  enableTrigger: PropTypes.bool,
  buddyDetails: PropTypes.array,
  sendFriendRequest: PropTypes.func,
  onChange: PropTypes.func,
  handleModal: PropTypes.func.isRequired,
  handleFeed: PropTypes.func,
  unFriendUser: PropTypes.func,
  getPostLikeDetails: PropTypes.func,
  feedNumber: PropTypes.number,
  postLikeDetails: PropTypes.array,
  data: PropTypes.array.isRequired,
  addBuddy: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  showRecognition: PropTypes.func,
  feedNo: PropTypes.number,
  value: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  updateFeedsLike: PropTypes.func,
  editPost: PropTypes.func,
  userPosts: PropTypes.array,
  socialCallBack: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  postReply: PropTypes.func,
  handleScrollTrigger: PropTypes.func,
  t: PropTypes.func,
  indexProp: PropTypes.number,
  feed: PropTypes.object.isRequired,
  inspirationPost: PropTypes.array
};

const mapStateToProps = (state) => ({
  comments: state.social.comments,
  replySuccess: state.social.replySuccess,
  disableButton: state.social.disableButton,
  successLikeOrUnlike: state.social.successLikeOrUnlike,
  isLoading: state.social.isLoading,
  user: state.profileData.user,
  userCompany: state.profileData.userCompany,
  postLikeDetails: state.social.postLikeDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCommentsOnPost: (id) => dispatch(getCommentsOnPost(id)),
  postReplyOnComment: (commentId, reply, challengeId, shoutout, shoutoutUserName) => dispatch(addCommentReplyOnPost(commentId, reply, challengeId, shoutout, shoutoutUserName)),
  likeOrUnlikePost: (data, updationList) => dispatch(likeUnlikePost(data, updationList)),
  disableLikeButton: () => dispatch(disableButton()),
  getPostLikeDetails: (data) => dispatch(getPostLikeDetails(data)),
  postComment: (commentData, updationList) => dispatch(addCommentOnPost(commentData, updationList)),
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(SinglePhoto));