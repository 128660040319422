import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

import { LayoutV2, PhotosContainerV2 } from './styles';
import { AmigosContainerV2 } from '../MyAmigos/styles';
import { NoPosts } from '../UserPosts/styles';

import SinglePhoto from './SinglePhoto';
import Waiting from '../Waiting';

import { getCommentsOnPost, getCompanyExemplifiesValues, getUserPostImages, getUserProfileImage } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import ViewPhoto from '../ViewCommentModal/viewImagePopup';
const LikePopUp = React.lazy(() => import('../LikePopUp'));

class MyPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
      showViewCommentModals: false,
      imageV: {},
      feedNumber: 0,
      showLikePostModal: false,
      showThreeComments: false,
      showCommentsFor: '',
      showImagePopup: false,
      imageValuePopup:{}
    }
  }

  componentDidMount() {
    this.props.getCompanyExemplifiesValues()
    if (!this.props.profileImage) {
      this.props.fetchUserProfileImage();
    }
  }

  setActiveTab = (active) => {
    this.setState({ selectedTab: active })
  }

  renderViewCommentAndGetComment = (value) => {
    this.setState(() => ({
      // showViewCommentModals: !this.state.showViewCommentModals,
      // imageV: id,
      showImagePopup: !this.state.showImagePopup,
      imageValuePopup:value
    }))
  }

  renderViewCommentModal = () => {
    this.setState(() => ({
      showViewCommentModals: !this.state.showViewCommentModals,
    }))
  }

  getCommentsForPost = (id) => {
    const { fetchCommentsOnPost } = this.props;
    this.setState({
      showCommentsFor: id,
      showThreeComments: true
    });
    fetchCommentsOnPost(id);
  };

  getCommentsForPostModal = (id) => {
    const { fetchCommentsOnPost } = this.props;
    fetchCommentsOnPost(id);
  };

  feeding = (key) => {
    this.setState({
      feedNumber: key
    });
  };

  handleLikePostModal2 = () => {
    this.setState(() => ({
      showLikePostModal: !this.state.showLikePostModal,
    }));
  };


  render() {
    const { userPostImages, currentUserId, history, t, profileImage, enableTrigger } = this.props;

    if (isUndefined(userPostImages)) {
      return <Waiting />
    }
    return (
      <LayoutV2>
        {
          userPostImages && userPostImages.length > 0 ?
            <PhotosContainerV2>
              <AmigosContainerV2>
                {
                  userPostImages.map((photo, index) => (
                    <SinglePhoto
                      key={`${index}-${photo.id}`}
                      photo={photo}
                      currentUserId={currentUserId}
                      history={history}
                      showViewCommentModals={this.state.showViewCommentModals}
                      renderViewCommentModal={this.renderViewCommentModal}
                      renderViewCommentAndGetComment={this.renderViewCommentAndGetComment}
                      imageV={this.state.imageV}
                      exemplifiesValues={this.props.exemplifiesValues}
                      getCommentsForPost={this.getCommentsForPost}
                      getCommentsForPostModal={this.getCommentsForPostModal}
                      profileImage={profileImage}
                      enableTrigger={enableTrigger}
                      indexProp={index}
                      handleFeed={this.feeding}
                      handleModal={this.handleLikePostModal2}
                      feedNumber={this.state.feedNumber}
                      userPosts={userPostImages}
                      updateFeedsLike={this.props.updateFeedsLike}
                      updationList="userPostImages"
                      userId={this.props.userId}
                    />
                  ))
                }
              </AmigosContainerV2>
            </PhotosContainerV2> :
            <NoPosts> {t("No Photos Available")}</NoPosts>
        }
        {this.state.showImagePopup && this.state.imageValuePopup &&<ViewPhoto
          image={this.state.imageValuePopup.image}
          showModal={this.state.showImagePopup}
          key={`feed-${this.state.imageValuePopup.id}`}
          onClose={this.renderViewCommentAndGetComment}
        />}
        {
          this.state.showLikePostModal && <LikePopUp onClose={() => this.handleLikePostModal2()} showModal={this.state.showLikePostModal} feedNumber={this.state.feedNumber} />
        }
      </LayoutV2>
    )
  }
}

MyPhotos.propTypes = {
  history: PropTypes.object.isRequired,
  userPostImages: PropTypes.array,
  fetchUserPostImages: PropTypes.func.isRequired,
  userId: PropTypes.number,
  currentUserId: PropTypes.number,
  otherDetails: PropTypes.object,
  t: PropTypes.func,
  getCompanyExemplifiesValues: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  fetchCommentsOnPost: PropTypes.func.isRequired,
  fetchUserProfileImage: PropTypes.func,
  profileImage: PropTypes.string,
  enableTrigger: PropTypes.bool,
  updateFeedsLike: PropTypes.func
};

const mapStateToProps = (state) => ({
  userPostImages: state.social.userPostImages,
  exemplifiesValues: state.social.exemplifiesValues,
  profileImage: state.profileData.profileImage,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserPostImages: (id) => dispatch(getUserPostImages(id)),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues()),
  fetchCommentsOnPost: (id) => dispatch(getCommentsOnPost(id)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyPhotos));